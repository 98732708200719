<template>
    <article draggable="true" @dragstart="startDrag" @dragend="stopDrag">
        <div class="priority" :class="[currentPriority.toLowerCase()]"></div>
        <div class="info">
            <main>
                <span class="title">{{data.title || 'Ticket'}}</span>
                <span class="product" v-if="data.product">{{data.product.name}} #{{data.product.serial_no}}</span>
                <span class="client" v-if="data.project">{{data.project.name}}</span>
                
            </main>
            <div class="edits">
                <Priority :value="data.ticket_priority.priority_details" @newPrio="handleNewPrio" />
                <Assign :column="column" :users="users" :value="data" @newUser="handleNewUser"/>
            </div>
        </div>
        <div class="options" @click="edit = !edit" >
            <img src="/assets/images/black_dots.svg" alt="" />
            <options-modal v-if="edit" :options="['view', 'edit']" @view="goToTicket" @edit="editTicket = true" @delete="del()" />
        </div>
    </article>
    <edit-modal
        :title="`Edit ticket`"
        :items="toEdit"
        v-if="editTicket"
        @close="editTicket = false"
        @submitData="editTicketData"
    />
    <delete
        v-if="deleteTicket.length"
        :ticket="deleteTicket[0]"
        @close="handleDelete"
    />
</template>

<script>
import Priority from './Priority.vue'
import Assign from './Assign.vue'
import axiosInstance from '@/services/AxiosTokenInstance'
import OptionsModal from '@/components/Ui/General/Options.vue'
import EditModal from "@/components/Ui/CRUD/Edit.vue";
import Delete from "../Delete.vue";

export default {
    props: {
        data: {
            type :Object,
            required: true
        },
        users: {
			type: Array,
			required: false,
			default: () => []
		},
        allStatus: {
			type: Array,
			required: false,
			default: () => []
		},
        column: {
            type: Number,
            required: true
        }
    },

    components: {
        Priority,
        Assign,
        OptionsModal,
        EditModal,
        Delete
    },

    emits: ['startDrag', 'stopDrag', 'updateData'],

    data() {
        return {
            currentPriority: this.data.ticket_priority.priority_details.name,
            edit: false,
            editTicket: false,
            priorities: [
                {
                    id: 1,
                    image: "/assets/images/low_flag.svg",
                    name: "low",
                },
                {
                    id: 2,
                    image: "/assets/images/medium_flag.svg",
                    name: "medium",
                },
                {
                    id: 3,
                    image: "/assets/images/high_flag.svg",
                    name: "high",
                },
            ],
            imgURL: process.env.VUE_APP_DO_SPACES,
            deleteTicket: []
        }
    },

    computed: {
        toEdit() {
            return [
                {
                    type: "text",
                    label: "Ticket name",
                    value: this.data.title,
                },
                {
                    type: "select",
                    label: "Status",
                    options: this.allStatus,
                    value: this.allStatus.filter(
                        (el) => el.id === this.data.ticket_status.status
                    )[0],
                },
                {
                    type: "select",
                    label: "Priority",
                    options: this.priorities,
                    value: {
                        id: this.data.ticket_priority.priority_details.id,
                        name: this.data.ticket_priority.priority_details.name,
                        image: null
                    },
                },
                {
                    type: "multiple-select",
                    label: "Assignees",
                    options: this.users,
                    value: this.data.assigned_users.length
                        ? this.data.assigned_users.map((el) => {
                            return {
                            id: el.id,
                            name: el.name,
                            image: el.avatar ? this.imgURL + el.avatar : null,
                            };
                        })
                        : [],
                },
            ];
        }
    },

    watch: {
        data() {
            this.currentPriority = this.data.ticket_priority.priority_details.name
        }
    },

    methods: {
        async handleNewPrio(value) {
            this.currentPriority = value.name

            let toSend = {
                id_ticket: this.data.id,
                status: value.id
            };

            await axiosInstance.post('ticket-priority-change', toSend)
        },

        async handleNewUser(data) {
            const org = JSON.parse(localStorage.getItem("organization"));

            let toSend = {
                id_organization: org.id,
                id_ticket: this.data.id,
                users: [{id_user: data.id}]
            };

            await axiosInstance.post('user-assign-ticket', toSend)
        },

        startDrag() {
            this.$emit('startDrag', this.data)
        },

        async stopDrag(e) {
            e.preventDefault();
            this.$emit('stopDrag')
        },

        goToTicket() {
            this.$router.push({path: `/remote-support/tickets/${this.data.id}`})
        },

        async editTicketData(data) {
            const org = JSON.parse(localStorage.getItem("organization"));

            let toSend = {
                org_id: org.id,
                title: data["Ticket name"],
                priority_id: data["Priority"].id,
                users: data.newAssignees ? data.newAssignees.map((el) => el.id) : [],
                delet_users: data.deletedAssignees
                ? data.deletedAssignees.map((el) => el.id)
                : [],
                labels: data.newLabels ? data.newLabels.map((el) => el.id) : [],
                delete_labels: data.deletedLabels
                ? data.deletedLabels.map((el) => el.id)
                : [],
                status_id: data.Status.id,
            };

            const response = await axiosInstance.post(
                `ticket/${this.data.id}`,
                toSend
            );

            if (response) this.$emit("updateData");
        },

        del() {
            this.deleteTicket.push(this.data);
        },

        async handleDelete(value) {
            if (value) {
                const id = this.deleteTicket[0].id;
                await axiosInstance.delete(`ticket/${id}`).finally(() => {
                    this.$emit("updateData");
                    this.deleteTicket = [];
                });
            } else this.deleteTicket = [];
        }
    },
}
</script>

<style lang="scss" scoped>
article{
    width: 100%;
    display: flex;
    box-shadow: 0 1px 6px rgba(202, 214, 226, 0.5);
    cursor: pointer;
    user-select: none;
    position: relative;

    .priority {
        width: 2%;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        transition: 0.2s;

        &.high {
          background: #ed0423;
        }
        &.medium {
          background: #fb9a4b;
        }
        &.low {
          background: #cad6e2;
        }
    }
    .info {
        width: 98%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;

        main {
            display: flex;
            flex-direction: column;

            .title {
                font-weight: 600;
            }

            .product {
                font-size: 0.8rem;
                font-weight: 500;
            }

            .client {
                color: $grey;
                font-size: 0.8rem;
            }
        }

        .edits {
            display: flex;
            gap: 0.5rem;
            .prios, .assign {
                width: 50%;
            }

            .assign {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .options {
        position: absolute;
        top: 5px;
        right: 10px;
    }
}
</style>